.App{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 1vmin);
}

.LoginBox{
  min-height: 50vh;
  min-width:100vh;
  align-self: center;
  justify-self: center;
  margin-top: 1em;
}

.SpinnerContainer{
  align-self: center;
  justify-self: center;
  height: "100%";
  width: "100%";
}

.RedirectLink{
  color:white;
}

.CalendarBackground{
  min-height: 80vh;
  min-width:100vh;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding: 2em;
}

.CalendarContainer{
  min-height: 20vh;
  width:50%;
  background-color: white;
  border-radius: 2em;
  padding:2em;
}

.MonthRow{
  display: flex;
  height:8%;
  width:100%;
  margin-bottom: 1em;
  justify-self: center;
}

.WeekRow{
  flex-direction: row;
  flex:1;
}

.DayBox{
  width:14%;
  height:12vh;
  border-width: 0;
  text-align: center;
  background-color: white;
  border-radius: 1em;
  position: relative;
}

.QueryDayBox{
  width:14%;
  height:12vh;
  border-width: 0;
  text-align: center;
  background-color: #0CE8AA;
  border-radius: 1em;
  position: relative;
}

.PendingDecisionLabel{
  width:100%;
  height:25%;
  background-color: #ED553B;
  z-index: 1002;
  position: absolute;
  left:0;
}

.RecruitingLabel{
  width:100%;
  height:25%;
  background-color: #F6D55C;
  z-index: 1000;
  position: absolute;
  left:0;
}

.RecruitedLabel{
  width:100%;
  height:25%;
  background-color: #282E47;
  z-index: 999;
  position: absolute;
  left:0;
}

.OrderFormContainer{
  background-color: white;
  display:flex;
  flex-direction: column;
  width: 50%;
  min-height: 20vh;
  border-radius: 2em;
  left:-100px;
  padding:2em;
  padding-left: 3em;
  max-height: 100%;
}

.FormContainer{
  height:80%;
  flex:10
}

.RequestRowContainer{
  height:80%;
  flex:10;
}

.ButtonOptionRow{
  flex-direction: row;
  flex:1;
}

.RequestRow{
  border-radius: 1em;
  flex:1;
  min-height:4em;
  padding:10px;
  background-color: black;
}

.MembersRow{
  flex:1;
  margin:30px;
  padding:15px;
  align-items: center;
}

.Avatar{
  height:50px;
  width:auto;
  margin-right:1em;
}

.signoutButton{
  margin-left: auto;
}

.jumbotron1 {
  background-image: url("./HomePage.jpg");
  background-size: cover;
  min-height: 400px;
  background-position-y: 500px no-repeat;
}

.jumbotron2 {
  background-image: url("./login.jpg");
  background-size: cover;
  min-height: 350px;
}

.RecruitedCard{
  height:18em;
  overflow:scroll;
}

.WeekdaysRow{
  display:flexbox;
  justify-content: space-around;
}